<!--  -->
<template>
    <div class=''>
        <div style="font-size: 28px">心电复核记录</div>
        <!--    显示数据包括病人姓名（用户名）、申请复核时间、症状备注（纯文本，使用按钮打开弹出框显示）。
        不显示的数据包括医生ID，病人ID，预约编号aID，邀请链接（不确定是否存在这里）。以上数据考虑是否拆成两组甚至更多组来存储-->
        <el-divider></el-divider>
        <!--    根据病人姓名关键词查询待复核记录-->
        <!-- <el-input style="width: 30%" placeholder="请输入查询内容" v-model="search" clearable>
        </el-input> -->

        <el-table :default-sort="{ prop: 'createTime', order: 'descending' }" :data="checkListData">
            <el-table-column label="用户" prop="uid"></el-table-column>
            <el-table-column label="类型" prop="type">
                <template slot-scope="scope">
                    <!-- 待补充其他type对应名字 -->
                    {{ scope.row.type === 'ecg_static' ? '静态单导联' : (scope.row.type === 'ecg_dynamic' ? '动态单导联' :
                        (scope.row.type === 'ecg8_static' ?
                            '静态八导联' : (scope.row.type === 'ecg8_dynamic' ? '动态八导联' : 'VIP'))) }}
                </template>
            </el-table-column>
            <el-table-column label="申请时间" prop="createTime" sortable></el-table-column>
            <el-table-column label="症状备注" prop="userDescribe"></el-table-column>

            <el-table-column label="查看记录" prop="record">
                <template v-slot="scope">
                    <el-button @click="click2Content(scope.row)" type="primary">查看详情</el-button>
                </template>
            </el-table-column>

            <el-table-column prop="status" label="复核状态" sortable
                :filters="[{ text: '已复核', value: '1' }, { text: '待复核', value: '0' }]" :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status === '0' ? 'warning' : 'success'" disable-transitions
                        style="font-size: 14px;">{{ scope.row.status === '0' ? '待复核' : '已复核' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="复核时间" prop="checkTime" sortable></el-table-column>

        </el-table>
        <!--<el-divider></el-divider>
        <el-button @click="click0()">远程</el-button>-->
    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

            checkListData: [
                {
                    checkTime: null,
                    conId: 105,
                    conclusion: null,
                    createTime: "2023-10-07 14:03:08",
                    docId: 1,
                    recordId: "102",
                    status: "0",
                    type: "ecg8_static",
                    uid: 3456,
                    userDescribe: "赵医生",
                    recordTime: "",
                }
            ],
            search: '',

        };
    },
    //监听属性 类似于data概念
    computed: {
        crossPlatformStatus: {
            get() { return this.$store.state.module.crossPlatform },
            set(val) { this.$store.commit('module/updateCrossPlatform', val) }
        },
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        filterTag(value, row) {
            return row.checkStatus === value;
        },
        getcheckList() {
            this.$http({
                url: this.$http.adornUrl("/doctor/ecgRecord/getEcgRecord"),
                method: 'get',
                params: this.$http.adornParams({ "docId": this.$store.state.doctor_station.docid })
            }).then(({ data }) => {
                console.log(data.list)
                this.checkListData = data.list
            })
        },
        click2Content(e) {
            console.log(e)
            this.crossPlatformStatus = true
            this.$router.push({
                // name: 'HistorySingo2',
                name: 'checkContent',
                query: {
                    uid: e.uid,
                    recordId: e.recordId,
                    type: e.type,
                    checkDoneTime: e.checkTime,
                    checkCreateTime: e.createTime,
                    recordTime: e.recordTime,  
                    userDescribe: e.userDescribe,
                    checkStatus: e.status,
                    DocConclusion: e.conclusion,
                    DocConId: e.conId,
                }
            })
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getcheckList()
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped></style>